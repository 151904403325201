import { useEffect, useState } from 'react';

import { useAuctionService } from 'providers/AuctionServiceProvider';
import { ETypeClaim, IAuction } from 'providers/interfaces';
import { useWalletData } from 'providers/NearWalletProvider';
import { UPDATE_AUCTION_INTERVAL, ZERO } from 'shared/constant';
import { formatAuction, getTypeClaim } from 'shared/utils';

const useAuctionDynamicData = (auction: IAuction) => {
  const { isSignedIn, accountId } = useWalletData();
  const { auctionContract } = useAuctionService();

  const [winnerBid, setWinnerBid] = useState<string>(auction.winnerBid);
  const [yourBid, setYourBid] = useState<string>(auction.userData?.amount || ZERO);
  const [typeClaim, setTypeClaim] = useState<ETypeClaim>(auction.typeClaim);

  useEffect(() => {
    const updateDynamicData = async () => {
      try {
        if (!auctionContract) return;
        const updatedAuction = await auctionContract.getAuction(auction.id);
        if (!updatedAuction) return;
        const newUpdatedAuction = formatAuction(updatedAuction);
        if (isSignedIn) {
          const auctionDataByUser = await auctionContract.getAuctionAccount(auction.id);
          newUpdatedAuction.userData = auctionDataByUser;
          newUpdatedAuction.typeClaim = getTypeClaim(newUpdatedAuction, auctionDataByUser, accountId);
        }
        setWinnerBid(newUpdatedAuction.winnerBid);
        setYourBid(newUpdatedAuction.userData?.amount || ZERO);
        setTypeClaim(newUpdatedAuction.typeClaim);
      } catch (e){
        console.warn(`Error: ${e} while update auction data`);
      }
    };
    updateDynamicData();
    const interval = setInterval(updateDynamicData, UPDATE_AUCTION_INTERVAL);
    return () => clearInterval(interval);
  }, [auctionContract, isSignedIn]);

  return ({ winnerBid, yourBid, typeClaim });
};

export default useAuctionDynamicData;
