import Big from 'big.js';
import {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';

import getConfig from 'services/config';
import { AuctionContract, FungibleTokenContract } from 'services/contracts';
import { Action } from 'services/interfaces';
import { ZERO } from 'shared/constant';
import { EModals } from 'shared/providers/interfaces';
import { useModalStore } from 'shared/providers/ModalProvider';
import { EStatus, formatTokenAmount } from 'shared/utils';

import { AuctionServiceContextType, IAuction } from './interfaces';
import { useWalletData } from './NearWalletProvider';

const config = getConfig();

const AuctionServiceContextHOC = createContext<AuctionServiceContextType>({} as AuctionServiceContextType);

export function AuctionServiceProvider({ children }:{ children: JSX.Element }) {
  const [auctionContract, setAuctionContract] = useState<AuctionContract | undefined>();
  const { showModal } = useModalStore();
  const {
    wallet, near, accountId, isSignedIn, sendTransaction,
  } = useWalletData();

  useEffect(() => {
    if (!near || !wallet) return;
    const createInstance = async () => {
      const instance = new AuctionContract(accountId, config.contractId, near);
      const connectedToNearInstance = instance.withConnect(near);

      if (wallet && accountId) connectedToNearInstance.withWalletConnection(wallet);

      setAuctionContract(connectedToNearInstance);
    };

    createInstance();
  }, [accountId, near, wallet]);

  const sendBid = useCallback(async (auctionId: number, token: FungibleTokenContract, amount: string) => {
    try {
      if (!auctionContract) return;
      const transactions: Action[] = [];
      const isJoined = await auctionContract.hasAccount();
      if (!isJoined) {
        const joinFee = await auctionContract?.getJoinFee();
        if (!joinFee) return;
        const join = auctionContract.generateJoinTransaction(joinFee);
        transactions.push(...join);
      }
      const placeBid = await auctionContract.generatePlaceBidTransaction(auctionId, token, amount);
      transactions.push(...placeBid);
      await sendTransaction(transactions);
    } catch (e){
      console.warn(`Error: ${e} while user SEND_BED in AuctionServiceProvider`);
    }
  }, [auctionContract, sendTransaction]);

  const showHighBidModal = useCallback((auctionId: number, token: FungibleTokenContract, amount: string) => {
    showModal(EModals.HIGH_BET_MODAL, {
      handleConfirm: () => {
        sendBid(auctionId, token, amount);
      },
    });
  }, [sendBid, showModal]);

  const placeBid = useCallback(async (balance: string, auction: IAuction, token: FungibleTokenContract) => {
    if (
      (!auction || !token || !auctionContract) && (auction.status !== EStatus.Open)
    ) return;
    showModal(EModals.DEPOSIT_MODAL, {
      token,
      balance,
      isSignedIn,
      auction,
      handleConfirm: (amount: string) => {
        if (!auction.userData) return;
        const formattedYourAmount = formatTokenAmount(auction.userData.amount, token.metadata.decimals);
        const newAmount = Big(amount).minus(formattedYourAmount).toFixed();
        if (Big(auction.winnerBid).eq(auction.userData.amount) && !Big(auction.userData.amount).eq(ZERO)){
          showHighBidModal(auction.id, token, newAmount);
          return;
        }
        sendBid(auction.id, token, newAmount);
      },
    });
  }, [auctionContract, showHighBidModal, isSignedIn, sendBid, showModal]);

  const claimNFT = useCallback(async (auctionId: number) => {
    if (!auctionContract) return;
    const transaction = auctionContract.generateClaimNFTTransaction(auctionId);
    await sendTransaction(transaction);
  }, [auctionContract, sendTransaction]);

  const claimRefund = useCallback(async (auctionId: number, token: FungibleTokenContract) => {
    if (!auctionContract) return;
    const transaction = await auctionContract.generateClaimRefundTransaction(auctionId, accountId, token);
    await sendTransaction(transaction);
  }, [accountId, auctionContract, sendTransaction]);

  const auctionServiceData = useMemo(() => ({
    auctionContract,
    placeBid,
    claimNFT,
    claimRefund,
  }), [auctionContract, placeBid, claimNFT, claimRefund]);

  return (
    <AuctionServiceContextHOC.Provider value={auctionServiceData}>
      {children}
    </AuctionServiceContextHOC.Provider>
  );
}

export const useAuctionService = () => useContext(AuctionServiceContextHOC);
