import { ReactComponent as BookIcon } from 'assets/images/icons/book.svg';
import { ReactComponent as LightningIcon } from 'assets/images/icons/lightning.svg';
import { ReactComponent as LinkIcon } from 'assets/images/icons/link.svg';
import { ReactComponent as ListIcon } from 'assets/images/icons/list.svg';
import { ReactComponent as ReferralIcon } from 'assets/images/icons/referral.svg';
import { IBurgerMenu } from 'shared/interfaces';

export const burgerMenuList: IBurgerMenu[] = [
  {
    title: 'burgerMenu.about',
    logo: LightningIcon,
    link: '',
  },
  {
    title: 'burgerMenu.whitepaper',
    logo: BookIcon,
    link: '',
  },
  {
    title: 'burgerMenu.referralProgram',
    logo: ReferralIcon,
    link: '',
  },
  {
    title: 'burgerMenu.risksAndRules',
    logo: ListIcon,
    link: '',
  },
];

export const launchpadsList: IBurgerMenu[] = [
  {
    title: 'burgerMenu.openMoon',
    logo: LinkIcon,
    link: 'https://moon.bocachica.io/',
  },
  {
    title: 'burgerMenu.openMars',
    logo: LinkIcon,
    link: 'https://mars.bocachica.io/',
  },
];
