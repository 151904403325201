import Big from 'big.js';

import { ZERO } from 'shared/constant';
import { EInputHelperText } from 'shared/interfaces';

const getInputHelperText = (currentBid: string, bidAmount: string, balance: string, auctionMinStep: string) => {
  const validCurrentBid = Big(currentBid || ZERO).add(auctionMinStep);
  if (Big(bidAmount).gt(ZERO) && Big(bidAmount).lt(validCurrentBid)) return EInputHelperText.LOW_BID;
  if (Big(balance).lt(bidAmount)) return EInputHelperText.LOW_BALANCE;
  if (Big(bidAmount).gt(ZERO)) return EInputHelperText.LOCKED_AMOUNT;
  return null;
};

export default getInputHelperText;
