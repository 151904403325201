import Big from 'big.js';

import { FungibleTokenContract, NonFungibleTokenContract } from 'services/contracts';
import { INFTTokenMetadata } from 'services/interfaces';
import { ITranslationKeys } from 'services/translation';
import { ZERO, INCREASE_VALUES, EDimensions } from 'shared/constant';
import { IBidArray, INftDataArray } from 'shared/interfaces';

import { displayBid } from './displayAmount';
import { formatTokenAmount } from './formatAmount';
import { copy, isNotNullOrUndefined } from './index';
import { trimString } from './stringOperation';

interface IBidDetails {
  currentBid: string,
  yourBid?: string,
  minMarkup?: string,
  token: FungibleTokenContract,
  isShowingMinMarkup?: boolean,
}

const generateColumn = (
  title: ITranslationKeys,
  value: string,
  symbol: string,
  condition = true,
) => {
  if (condition) return { title, value: `${value} ${symbol}` };
  return null;
};

export const getBidDetailsArray = ({
  currentBid,
  yourBid,
  minMarkup,
  token,
  isShowingMinMarkup = true,
}: IBidDetails): IBidArray[] => {
  const winnerBid = displayBid(currentBid, token.metadata.decimals);
  const formattedYourBid = displayBid(yourBid || ZERO, token.metadata.decimals);
  const formattedMinMarkup = formatTokenAmount(minMarkup || ZERO, token.metadata.decimals);
  const bidArray = [
    generateColumn('bid.currentBid', winnerBid, token.metadata.symbol),
    generateColumn('bid.minMarkup', formattedMinMarkup, token.metadata.symbol, isShowingMinMarkup),
    generateColumn('bid.yourBid', formattedYourBid, token.metadata.symbol, Big(yourBid || ZERO).gt(ZERO)),
  ].filter(isNotNullOrUndefined);

  return bidArray;
};

export const increaseValueArray = (token: FungibleTokenContract) => INCREASE_VALUES.map((value) => ({
  title: `+ ${value} ${token.metadata.symbol}`,
  value,
}));

export const nftData = (
  dimension: EDimensions,
  contractNft: NonFungibleTokenContract,
  nft: INFTTokenMetadata,
  token: FungibleTokenContract,
  auctionMinStep: string,
): INftDataArray[] => {
  const minMarkup = formatTokenAmount(auctionMinStep, token.metadata.decimals);
  const contractAddress = trimString(dimension === EDimensions.SMALL, contractNft.contractId);
  const owner = trimString(dimension === EDimensions.SMALL, nft.owner_id);

  return [
    {
      title: 'nftData.contractAddress',
      value: contractAddress,
      onClick: () => copy(contractNft.contractId),
    },
    {
      title: 'nftData.owner',
      value: owner,
      onClick: () => copy(nft.owner_id),
    },
    {
      title: 'bid.minMarkup',
      value: `${minMarkup} ${token.metadata.symbol}`,
    },
    {
      title: 'nftData.tokenID',
      value: nft.token_id,
      onClick: () => copy(nft.token_id),
    },
  ];
};

export const getCurrentBid = (initialPrice: string, winnerBid: string): string => (
  (Big(initialPrice).gt(ZERO) && Big(initialPrice).gt(winnerBid)) ? initialPrice : winnerBid
);
